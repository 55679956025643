<template>
    <div>
        <TrackingComponent />
    </div>
</template>

<script>
import TrackingComponent from "@/components/folderHouse/tracking.vue";

export default {
    name: "Tracking",
    components: {
        TrackingComponent
    }
}
</script>